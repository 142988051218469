import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker"; // Correctly import getDuService
import { FinAidYearsResponse } from "types/FinAidYearsResponse";

// Fetch Financial Aid Year data
interface FinAidYear {
  AidYear: string;
  AidYearDescr: string;
  Institution?: string;
}

const FafsaStatus = () => {
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const [subheader, setSubHeader] = useState("");
  const [showlink, setShowlink] = useState<boolean>(false);
  // const FinAidYear: FinAidYear[]=[];
  const getFinancialAidYear = async () => {
    try {
      const getFinancialAidYearData = (await getDuService({
        service: "accountService",
        method: "getStudentFinAidYear",
      })) as FinAidYearsResponse;

      const data = getFinancialAidYearData;
      const FinAidYear = data.FinAidYearsResp.FinAidYears;
      generateFafsaMessage(FinAidYear);
    } catch (error) {
      console.error("Error fetching financial aid year:", error);
      return null;
    }
  };

  useEffect(() => {
    // Fetch financial aid year data and update the message based on the user's FAFSA status
    getFinancialAidYear();
  }, []);

  // Generate message based on the user's financial aid status
  const generateFafsaMessage = (finAidYears: FinAidYear[]) => {
    const currentAidYear = new Date().getFullYear().toString(); // Current aid year (this can be dynamic)
    if (finAidYears.length > 0) {
      const info = finAidYears.filter((year) => year.AidYear == currentAidYear);

      if (info.length > 0) {
        setHeader("FAFSA Received.");

        setMessage(
          `Thank you for filing your FAFSA for ${info[0].AidYearDescr}. We have received your FAFSA, and you will receive an email update on the status of your application once our review is complete`
        );
        setShowlink(false);
      } else if (info.length == 0) {
        setHeader("Important Cost-Savings Reminder — ");
        setSubHeader("File Your 2024-2025 FAFSA.");
        setMessage(
          "All students should submit the FAFSA. Submit the FAFSA today."
        );
        setShowlink(true);
      } else {
        const pastYear = finAidYears
          .map((year) => parseInt(year.AidYear))
          .filter((year) => year < parseInt(currentAidYear))
          .sort((a, b) => b - a);
        const desc = finAidYears.filter(
          (year) => year.AidYear == pastYear[0].toString()
        );
        setHeader("Important Cost-Savings Reminder");
        setSubHeader("File Your New 2024-2025 FAFSA.");
        setMessage(
          `The last FAFSA we have on file for you is for ${desc[0].AidYearDescr}. You must submit the new 2024-2025 FAFSA in order to qualify for applicable grants, scholarships, or aid for upcoming classes. Submit the FAFSA today.`
        );
        setShowlink(true);
      }
    }
  };

  return (
    <section
      aria-labelledby="Scholarships"
      className="border-box -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)] moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)] relative mb-[1.25rem] mr-[1.75rem] bg-[#ffffff] p-[1.25rem]"
      style={{
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
        width: "95%",
      }}
    >
      <div className="flex-justify-between">
        <div className="text-left">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
            }}
          >
            {header}
          </h3>
          <h3>{subheader}</h3>
        </div>
      </div>

      <br />

      <p className="font-sans text-[0.9735rem] font-normal leading-[1.6em] text-[#3c3c43]">
        {message}
      </p>
      <br />
      {showlink && (
        <div className="flex-justify-between">
          <div className="text-left">
            <a
              id="submitFafsa"
              target="blank"
              href="https://studentaid.gov/h/apply-for-aid/fafsa"
              className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
            >
              Submit the FAFSA Today
            </a>
            – using UMGC's school code 011644
            <p></p>
          </div>
        </div>
      )}
    </section>
  );
};

angularize(FafsaStatus, "fafsaStatusReact", angular.module("duApp"), {});

export default FafsaStatus;
