/* eslint-disable no-fallthrough */
import { getService } from "react-in-angularjs";
import { AccountService } from "types/AccountServiceResponse";
import { EnrollmentResponse } from "types/classRegistrationService";
import { AddressResponse } from "types/profileAddressService";
import { DemogResponse } from "types/profileDemographicInfoService";
import { NameResponse } from "types/profileNameService";

import {
  CampusLocationResponse,
  ClassRegistrationResponse,
  ClassSectionResponse,
  InstructionModeValueResponse,
  RegistrationResponse,
  ShoppingCartResponse,
  StudentIDResponse,
  StudentTermResponse,
  SubjectValueResponse,
} from "./types/classSearchService";
import { countryListResponse } from "./types/countryListService";
import {
  PlannerCoursesResponse,
  ProgramDetailsResponse,
  TermsResponse,
} from "./types/degreePlanningDashboardService";
import { dutyAdvisorMailResponse } from "./types/dutyAdvisorMailService";
import { GradesResponse } from "./types/gradesService";
import { MilitaryInformationResponse } from "./types/profileMilitaryService";
import { TodosResponse } from "./types/TodosResponse";
export type DuService =
  | {
      service: "classSearchService";
      method:
        | "getTerms"
        | "getSubjectList"
        | "getCampusLocationList"
        | "getCampusCode"
        | "getFormatList"
        | "getRegistration"
        | "getShoppingCart"
        | "putShoppingCart"
        | "deleteShoppingCart"
        | "putAddToPlanner"
        | "deleteFromPlanner"
        | "getSearchData"
        | "getSearchLocData";
    }
  | {
      service: "gradesService";
      method: "getGrades";
    }
  | {
      service: "dashBoardService";
      method: "getProgramDetails" | "getCoursesList" | "getAddTermsList";
    }
  | {
      service: "classRegistrationService";
      method: "getEnrollments" | "postEnrollmentData" | "deleteEnrollmentData";
    }
  | { service: "profileMilitaryService"; method: "getProfileMilitary" }
  | {
      service: "countryListService";
      method: "getCountryList" | "getStateData";
    }
  | {
      service: "dutyAdvisorEmailService";
      method:
        | "getAdvisorEmailList"
        | "getAdvisorEurCountryList"
        | "getAdvisorAsiaCountryList";
    }
  | {
      service: "profileDemographicInfoService";
      method: "getProfileDemographicInfo" | "setProfileDemographicInfo";
    }
  | {
      service: "profileNameService";
      method: "getProfileName" | "setProfileInfo";
    }
  | {
      service: "accountService";
      method: "getAccBalance";
    }
  | {
      service: "todoService";
      method: "getTodos";
    }
  | {
      service: "profileAddressService";
      method: "getProfileAddress" | "setProfileAddress";
    };

const getDuService = (duService: DuService, param?: any) => {
  switch (duService.service) {
    case "classSearchService":
      switch (duService.method) {
        case "getTerms":
          return getService(
            duService.service
          ).getTerms() as Promise<StudentTermResponse>;
        case "getSubjectList":
          return getService(
            duService.service
          ).getSubjectList() as Promise<SubjectValueResponse>;
        case "getCampusLocationList":
          return getService(duService.service).getCampusLocationList(
            param
          ) as Promise<CampusLocationResponse>;
        case "getCampusCode":
          return getService(
            duService.service
          ).getCampusCode() as Promise<StudentIDResponse>;
        case "getFormatList": {
          switch (param) {
            case "ADLPH":
              return getService(
                duService.service
              ).getStateFormatList() as Promise<InstructionModeValueResponse>;
            case "EUROP":
              return getService(
                duService.service
              ).getCareerEuropeList() as Promise<InstructionModeValueResponse>;
            case "ASIA":
              return getService(
                duService.service
              ).getAsiaFormatList() as Promise<InstructionModeValueResponse>;
          }
        }
        case "getRegistration": {
          return getService(duService.service).getRegistration(
            param
          ) as Promise<RegistrationResponse>;
        }
        case "getShoppingCart": {
          return getService(duService.service).getShoppingCart(
            param
          ) as Promise<ShoppingCartResponse>;
        }
        case "putShoppingCart": {
          return getService(duService.service).putShoppingCart(
            param
          ) as Promise<any>;
        }
        case "deleteShoppingCart": {
          return getService(duService.service).deleteShoppingCart(
            param
          ) as Promise<any>;
        }
        case "putAddToPlanner": {
          return getService(duService.service).putAddToPlanner(
            param
          ) as Promise<any>;
        }
        case "deleteFromPlanner": {
          return getService(duService.service).deleteFromPlanner(
            param
          ) as Promise<any>;
        }
        case "getSearchData": {
          return getService(duService.service).getSearchData(
            param
          ) as Promise<ClassSectionResponse>;
        }
        case "getSearchLocData": {
          return getService(duService.service).getSearchLocData(
            param
          ) as Promise<ClassSectionResponse>;
        }
      }
    case "gradesService":
      switch (duService.method) {
        case "getGrades":
          return getService(
            duService.service
          ).getGrades() as Promise<GradesResponse>;
      }
    case "dashBoardService":
      switch (duService.method) {
        case "getProgramDetails": {
          return getService(duService.service).getProgramDetails(
            param
          ) as Promise<ProgramDetailsResponse>;
        }
        case "getCoursesList":
          return getService(duService.service).getCoursesList(
            param
          ) as Promise<PlannerCoursesResponse>;
        case "getAddTermsList":
          return getService(
            duService.service
          ).getAddTermsList() as Promise<TermsResponse>;
      }
    case "dutyAdvisorEmailService":
      switch (duService.method) {
        case "getAdvisorEmailList":
          return getService(duService.service).getAdvisorEmailList(
            param
          ) as Promise<dutyAdvisorMailResponse>;
        case "getAdvisorEurCountryList":
          return getService(
            duService.service
          ).getAdvisorEurCountryList() as Promise<dutyAdvisorMailResponse>;
        case "getAdvisorAsiaCountryList":
          return getService(
            duService.service
          ).getAdvisorAsiaCountryList() as Promise<dutyAdvisorMailResponse>;
      }
    case "classRegistrationService":
      switch (duService.method) {
        case "getEnrollments":
          return getService(duService.service).getEnrollments(
            param
          ) as Promise<ClassRegistrationResponse>;
        case "postEnrollmentData":
          return getService(duService.service).postEnrollmentData(
            param
          ) as Promise<EnrollmentResponse>;
      }
    case "profileMilitaryService": {
      switch (duService.method) {
        case "getProfileMilitary":
          return getService(
            duService.service
          ).getProfileMilitary() as Promise<MilitaryInformationResponse>;
      }
    }
    case "countryListService": {
      switch (duService.method) {
        case "getCountryList":
          return getService(
            duService.service
          ).getCountryList() as Promise<countryListResponse>;
        case "getStateData":
          return getService(
            duService.service
          ).getStateData() as Promise<countryListResponse>;
      }
    }
    case "profileDemographicInfoService": {
      switch (duService.method) {
        case "getProfileDemographicInfo":
          return getService(
            duService.service
          ).getProfileDemographicInfo() as Promise<DemogResponse>;
        case "setProfileDemographicInfo":
          return getService(duService.service).setProfileDemographicInfo(
            param
          ) as Promise<any>;
      }
    }
    case "profileNameService": {
      switch (duService.method) {
        case "getProfileName":
          return getService(
            duService.service
          ).getProfileName() as Promise<NameResponse>;
        case "setProfileInfo":
          return getService(duService.service).setProfileInfo(
            param
          ) as Promise<NameResponse>;
      }
    }
    case "profileAddressService": {
      switch (duService.method) {
        case "getProfileAddress":
          return getService(
            duService.service
          ).getProfileAddress() as Promise<AddressResponse>;
        case "setProfileAddress":
          return getService(duService.service).setProfileAddress(
            param
          ) as Promise<AddressResponse>;
      }
    }
    case "accountService": {
      switch (duService.method) {
        case "getAccBalance":
          return getService(
            duService.service
          ).getAccBalance() as Promise<AccountService>;
      }
    }
    case "todoService": {
      switch (duService.method) {
        case "getTodos":
          return getService(
            duService.service
          ).getTodos() as Promise<TodosResponse>;
      }
    }
  }
};
export default getDuService;
