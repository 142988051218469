import "./financialAid.css";

import ChevronDown from "icons/ChevronDown";
import ChevronRight from "icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { TodosResponse } from "types/TodosResponse";

interface financialAidTOdos {
  INDEX: number;
  ADMIN_FUNC: string;
  TO_DO_ITEM: string;
  DUE_DATE: string;
  STATUS: string;
  YEAR: string; // Extracted from the DUE_DATE field
}

const FinToDoDetail = () => {
  const [financialAidTodos, setFinancialAidTodos] = useState<
    financialAidTOdos[]
  >([]);
  const [isDueAsc, setDueAsc] = useState(true);

  const getTodosInfo = async (sortKey = "") => {
    try {
      const todoInfo = (await getDuService({
        service: "todoService",
        method: "getTodos",
      })) as TodosResponse;

      const itemList = todoInfo.UC_DU_TO_DO_RESP.UC_DU_TO_DO_ITEM || [];
      const todoList: financialAidTOdos[] = [];

      // Extract the year from DUE_DATE and filter only Financial Aid To-Dos with STATUS "Initiated"
      itemList.forEach((item, index) => {
        if (
          item.ADMIN_FUNC === "Financial Aid" &&
          item.STATUS === "Initiated"
        ) {
          const YEAR = item.DUE_DATE.split("-")[0]; // Extract the year from DUE_DATE

          todoList.push({
            INDEX: index,
            ADMIN_FUNC: item.ADMIN_FUNC,
            TO_DO_ITEM: item.TO_DO_ITEM,
            DUE_DATE: item.DUE_DATE,
            STATUS: item.STATUS,
            YEAR: YEAR, // Assign the extracted year to the item
          });
        }
      });

      // Sort by DUE_DATE descending (full date sorting)
      if (sortKey == "sortbydueasc") {
        const sortedTodos = todoList.sort(
          (a, b) =>
            new Date(a.DUE_DATE).getTime() - new Date(b.DUE_DATE).getTime()
        );
        setFinancialAidTodos(sortedTodos);
      } else if (sortKey == "sortbyduedsc") {
        const sortedTodos = todoList.sort(
          (a, b) =>
            new Date(b.DUE_DATE).getTime() - new Date(a.DUE_DATE).getTime()
        );
        setFinancialAidTodos(sortedTodos);
      }
    } catch (error) {
      console.error("Error fetching To-Dos:", error);
    }
  };

  useEffect(() => {
    getTodosInfo("sortbydueasc");
  }, []);

  const sortByDueDate = () => {
    if (isDueAsc) {
      getTodosInfo("sortbyduedsc");
      setDueAsc(false);
    } else {
      getTodosInfo("sortbydueasc");
      setDueAsc(true);
    }
    console.log(financialAidTodos);
  };

  useEffect(() => {
    setFinancialAidTodos(financialAidTodos);
  }, [financialAidTodos]);

  // Group To-Dos by the extracted YEAR
  const groupedTodos = financialAidTodos.reduce((grouped, todo) => {
    if (!grouped[todo.YEAR]) {
      grouped[todo.YEAR] = [];
    }
    grouped[todo.YEAR].push(todo);
    return grouped;
  }, {} as { [key: string]: financialAidTOdos[] });

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number
  ) => {
    event.preventDefault();
    // setData("FinancialTodoInfo");
    window.location.href = "#/fintoDoInfo/" + index;
  };

  return (
    <section
      aria-labelledby="Scholarships"
      id=""
      className="border-box resources-cls -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)]
       moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)]  
       relative mb-[1.25rem] mr-[0.05rem] bg-[#ffffff]"
      style={{
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
        marginLeft: "1.65rem",
        padding: "1.25rem 1.25rem 0",
      }}
    >
      <div className="flex-justify-between">
        <div className="text-left">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
              borderBottom: "0.5px solid #ccc",
              paddingBottom: "1rem",
            }}
          >
            <img
              src="https://portalstg.umgc.edu/images/du-todo-icon.svg"
              alt="icon"
              style={{
                width: "25px",
                height: "25px",
                display: "inline-flex",
              }}
            />{" "}
            Financial Aid To Dos ({financialAidTodos.length})
          </h3>
        </div>
      </div>

      <div
        className="flex-justify-between"
        style={{
          paddingBottom: "1rem",
          borderBottom: "0.5px solid #ccc",
        }}
      >
        <div>
          <section className="du-fintodo-content-section">
            {financialAidTodos &&
              financialAidTodos.length > 0 &&
              Object.keys(groupedTodos).map((year) => (
                <div key={year}>
                  <table
                    summary={`ToDos for ${year}`}
                    id="du-todo-table"
                    className="du-table du-table-left-align"
                  >
                    <thead>
                      <tr className="fin-todo-th">
                        <th className="du-no-bottom-padding du-no-top-padding">
                          <span className="fin-todo-all-title">Name</span>
                        </th>
                        <th className="du-no-bottom-padding du-no-top-padding">
                          <span className="fin-todo-all-title">
                            Due Date{" "}
                            {isDueAsc && (
                              <ChevronDown
                                className="w-6"
                                onClick={sortByDueDate}
                              />
                            )}
                            {!isDueAsc && (
                              <ChevronRight
                                className="w-2"
                                onClick={sortByDueDate}
                              />
                            )}
                          </span>
                        </th>
                        <th className="du-no-bottom-padding du-no-top-padding">
                          <span className="fin-todo-all-title after:font-awesome after:text-sm after:font-light after:content-['\f05a']">
                            Status
                          </span>{" "}
                          <ChevronDown className="w-6" />
                          <span className="absolute -left-[230px] -top-[34px] m-4 mx-auto w-[210px] rounded-md bg-du-light-gray p-2 text-[11px] text-sm opacity-0 transition-opacity group-hover:opacity-100">
                            Initiated status indicates To Dos have been entered
                            for your account and activation is pending. Active
                            status indicates To Dos are currently live for your
                            account and need to be resolved.
                          </span>
                        </th>
                        <th className="du-no-bottom-padding du-no-top-padding">
                          <span className="fin-todo-all-title">
                            Department <ChevronDown className="w-6" />
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedTodos[year].map((todo, index) => (
                        <tr className="fin-todo-row" key={index}>
                          <td>
                            <a
                              href="#"
                              title={todo.TO_DO_ITEM}
                              onClick={(event) =>
                                handleClick(event, todo.INDEX)
                              }
                            >
                              {todo.TO_DO_ITEM}
                            </a>
                          </td>
                          <td>
                            <span>{todo.DUE_DATE}</span>
                          </td>
                          <td>
                            <span>{todo.STATUS}</span>
                          </td>
                          <td>
                            <span>{todo.ADMIN_FUNC}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
          </section>
        </div>
      </div>
    </section>
  );
};

angularize(FinToDoDetail, "finToDoDetailReact", angular.module("duApp"), {});
export default FinToDoDetail;
