import "./financialAid.css";

import ChevronDown from "icons/ChevronDown";
import ChevronRight from "icons/ChevronRight";
import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import { financialAidTOdos } from "types/financialAidTodo";
import { TodosResponse } from "types/TodosResponse";

const FinToDescrip = () => {
  console.log("FinToDescrip");
  const [financialAidTodos, setFinancialAidTodos] = useState<
    financialAidTOdos[]
  >([]);
  // const [currentIndex, setCurrentIndex] = useState<number>(0);
  const selectedTodoInfo = localStorage.getItem("todoList");
  const selectedTodo = selectedTodoInfo ? JSON.parse(selectedTodoInfo) : null;

  //console.log(completeTodo);

  const goToNext = () => {
    console.log("clicked NExt");
  };

  useEffect(() => {
    const completeTodoInfoSet = localStorage.getItem("FinancialTodos");
    if (completeTodoInfoSet) {
      const parsedDatarArray: financialAidTOdos[] = completeTodoInfoSet
        ? JSON.parse(completeTodoInfoSet)
        : null;
      setFinancialAidTodos(parsedDatarArray);
      // console.log(JSON.parse(completeTodoInfoSet))
      console.log(financialAidTodos);
    }
    const currentselectedTodoInfo = localStorage.getItem("todoList");

    if (currentselectedTodoInfo) {
      const currentselectedTodo = currentselectedTodoInfo
        ? JSON.parse(currentselectedTodoInfo)
        : null;

      const parsedDatarArray = completeTodoInfoSet
        ? JSON.parse(completeTodoInfoSet)
        : null;
      const pos = parsedDatarArray.findIndex(
        (item: any) => item.INDEX == currentselectedTodo.INDEX
      );
      console.log(pos);
      console.log(parsedDatarArray[pos]);
      //console.log(dataArray[pos]);
    }
  }, []);

  //const {showButtons,setShowButtons}=useState(false);

  return (
    <div style={{ background: "#fff", padding: "2.813rem 3.125rem" }}>
      <h1>View All Financial Todos</h1>
      <h2 style={{ fontSize: "1.2rem", margin: "1rem 0" }}>
        {selectedTodo.TO_DO_ITEM}
      </h2>
      <br />
      <p style={{ fontSize: "0.9rem", padding: "0.25rem 0" }}>
        DueDate:{selectedTodo.DUE_DATE}
      </p>
      <p style={{ fontSize: "0.9rem", padding: "0.25rem 0" }}>
        Status:{selectedTodo.STATUS}
      </p>
      <p style={{ fontSize: "0.9rem", padding: "0.25rem 0" }}>
        Department:{selectedTodo.ADMIN_FUNC}
      </p>
      {/* {objData &&
      objData.UC_DU_TO_DO_DOC &&
      objData.UC_DU_TO_DO_DOC.contact == "Please visit http://help.umgc.edu" ? ( */}
      {selectedTodo.CONTACT === "Please visit http://help.umgc.edu" && (
        <p
          style={{
            fontSize: "0.9rem",
            padding: "0.25rem 0 1rem",
            display: "flex",
            position: "relative",
          }}
          className="relative cursor-[url(hand.cur),_pointer]"
        >
          <div
            className="cursor-pointer border-none before:mr-0 before:inline-block after:font-awesome after:text-sm after:font-light after:content-['\f05a']"
            title={selectedTodo.CONTACT}
          >
            Contact
          </div>
          :
          <span className="absolute -left-[230px] -top-[34px] m-4 mx-auto w-[210px] rounded-md bg-du-light-gray p-2 text-[11px] text-sm opacity-0 transition-opacity group-hover:opacity-100">
            {selectedTodo.CONTACT}
          </span>
          <a
            href="http://help.umgc.edu"
            target="_blank"
            style={{
              color: "rgba(0, 62, 106, 1)",
              fontWeight: 700,
              paddingLeft: "0.5rem",
            }}
            rel="noreferrer"
          >
            Help Center
          </a>
        </p>
      )}
      <div
        className="fin-to-descrip"
        dangerouslySetInnerHTML={{
          __html: selectedTodo.DESCRIPTION,
        }}
      ></div>
      <button onClick={goToNext}>Next</button>
    </div>
  );
};
angularize(FinToDescrip, "finToDescripReact", angular.module("duApp"), {});

export default FinToDescrip;
