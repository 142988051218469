import "./financialAid.css";

import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { financialAidTOdos } from "types/financialAidTodo";
import { TodosResponse } from "types/TodosResponse";

const FinToDo = () => {
  const [financialAidTodos, setFinancialAidTodos] = useState<
    financialAidTOdos[]
  >([]);

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    todo: financialAidTOdos
  ) => {
    event.preventDefault();
    // setData("FinancialTodoInfo");
    //dispatchCustomEvent();
    //`setSelected(todo);
    // console.log(todo);
    localStorage.setItem("todoList", JSON.stringify(todo));
    window.location.href = "#/fintoDoInfo/index=0";
  };

  const todoList: financialAidTOdos[] = [];
  const getTodosInfo = async () => {
    try {
      const todoInfo = (await getDuService({
        service: "todoService",
        method: "getTodos",
      })) as TodosResponse;

      const itemList = todoInfo.UC_DU_TO_DO_RESP.UC_DU_TO_DO_ITEM || [];
      console.log(itemList);
      // Extract the year from DUE_DATE and filter only Financial Aid To-Dos with STATUS "Initiated"
      itemList.forEach((item, index) => {
        if (
          item.ADMIN_FUNC === "Financial Aid" &&
          item.STATUS === "Initiated"
        ) {
          const YEAR = item.DUE_DATE.split("-")[0]; // Extract the year from DUE_DATE

          // todoInfo.UC_DU_TO_DO_RESP.UC_DU_TO_DO_ITEM.UC

          todoList.push({
            INDEX: index,
            TO_DO_ITEM: item.TO_DO_ITEM,
            DUE_DATE: item.DUE_DATE,
            STATUS: item.STATUS,
            YEAR: YEAR,
            ADMIN_FUNC: item.ADMIN_FUNC,
            // Assign the extracted year to the item
            CONTACT: item.UC_DU_TO_DO_DOC.CONTACT,
            DESCRIPTION: item.UC_DU_TO_DO_DOC.DESCRIPTION,
          });
        }
      });

      // Sort by DUE_DATE descending (full date sorting)
      const sortedTodos = todoList.sort(
        (a, b) =>
          new Date(b.DUE_DATE).getTime() - new Date(a.DUE_DATE).getTime()
      );
      setFinancialAidTodos(sortedTodos);
      localStorage.setItem("FinancialTodos", JSON.stringify(sortedTodos));
    } catch (error) {
      console.error("Error fetching To-Dos:", error);
    }
  };

  useEffect(() => {
    getTodosInfo();
  }, []);

  // Group To-Dos by the extracted YEAR
  const groupedTodos = financialAidTodos.reduce((grouped, todo) => {
    if (!grouped[todo.YEAR]) {
      grouped[todo.YEAR] = [];
    }
    grouped[todo.YEAR].push(todo);
    return grouped;
  }, {} as { [key: string]: financialAidTOdos[] });

  return (
    <div>
      <section
        aria-labelledby="Scholarships"
        id=""
        className="border-box resources-cls -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)]
       moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)]  
       relative mb-[1.25rem] mr-[0.05rem] bg-[#ffffff]"
        style={{
          boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
          marginLeft: "1.65rem",
          padding: "1.25rem 1.25rem 0",
        }}
      >
        <div className="flex-justify-between">
          <div className="text-left">
            <h3
              className="text-[1.5em]"
              style={{
                fontFamily: "'Roboto', Tahoma, sans-serif",
                fontWeight: "300",
                lineHeight: "1.4em",
                borderBottom: "0.5px solid #ccc",
                paddingBottom: "1rem",
              }}
            >
              <img
                src="https://portalstg.umgc.edu/images/du-todo-icon.svg"
                alt="icon"
                style={{
                  width: "25px",
                  height: "25px",
                  display: "inline-flex",
                }}
              />{" "}
              Financial Aid To Dos ({financialAidTodos.length})
            </h3>
          </div>
        </div>

        <div
          className="flex-justify-between"
          style={{
            paddingBottom: "1rem",
            borderBottom: "0.5px solid #ccc",
          }}
        >
          <div>
            <section className="du-fintodo-content-section">
              {Object.keys(groupedTodos).map((year) => (
                <div key={year}>
                  {/*<h4>{year}</h4> { Display Year }*/}
                  <table
                    summary={`ToDos for ${year}`}
                    id="du-todo-table"
                    className="du-table du-table-with-alternating-rows du-table-left-align"
                  >
                    <thead>
                      <tr>
                        <th className="du-no-bottom-padding du-no-top-padding">
                          <span className="du-strong-text-title">Name</span>
                        </th>
                        <th className="du-no-bottom-padding du-no-top-padding">
                          <span className="du-strong-text-title">Due Date</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedTodos[year].map((todo, index) => (
                        <tr key={index}>
                          <td>
                            <a
                              href="#"
                              title={todo.TO_DO_ITEM}
                              onClick={(event) => handleClick(event, todo)}
                            >
                              {todo.TO_DO_ITEM}
                            </a>
                          </td>
                          <td>
                            <span>{todo.DUE_DATE}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ))}
            </section>
          </div>
        </div>
        <div className="finTodo">
          <a href="#/finTodoDetails" title="">
            View All Financial Aid To Dos
          </a>
        </div>
      </section>
      <div></div>
    </div>
  );
};

angularize(FinToDo, "finToDoReact", angular.module("duApp"), {});
export default FinToDo;
